<template>
    <div class="">
        <v-dialog
            v-model="showModal"
            :max-width="maxWidth"
            persistent
            @click:outside="onCloseModal"
        >
            <v-card>
                <v-card-title class="text-subtitle-1 py-5 px-5 modal--border d-flex justify-space-between align-center">
                    <span>
                        <span>{{ title }}</span>
                    </span>
                    <v-hover
                        v-slot="{ hover }"
                        style="cursor: pointer;"
                    >
                        <v-icon
                            :color="hover ? '#000' : 'gray'"
                            @click="onCloseModal"
                        >
                            mdi-close
                        </v-icon>
                    </v-hover>
                </v-card-title>

                <v-card-text :class="`pt-5 pb-0 px-5`">
                    <v-form
                        ref="form"
                        v-model="valid"
                        lazy-validation
                    >
                        <v-row>
                            <v-col cols="12">
                                <v-select
                                    v-model="status_priority"
                                    :items="priorities"
                                    hide-details
                                    label="Priority"
                                    :rules="[rules.required]"
                                    outlined
                                >
                                    <template
                                        slot="selection"
                                        slot-scope="{ item }"
                                    >
                                        <img
                                            v-if="item.value !== 'all'"
                                            class="mr-2"
                                            :src="require(`@images/svg/${getIcon(item.value)}`)"
                                            :alt="item.value"
                                        >
                                        {{ item.text }}
                                    </template>
                                    <template
                                        slot="item"
                                        slot-scope="{ item }"
                                    >
                                        <img
                                            v-if="item.value !== 'all'"
                                            class="mr-2"
                                            :src="require(`@images/svg/${getIcon(item.value)}`)"
                                            :alt="item.value"
                                        >
                                        {{ item.text }}
                                    </template>
                                </v-select>
                            </v-col>
                            <!-- client autocomplete -->
                            <v-col cols="12">
                                <div class="d-flex">
                                    <v-autocomplete
                                        v-model="writer_id"
                                        clearable
                                        color="accent"
                                        outlined
                                        :items="writers"
                                        :loading="writerLoading"
                                        item-value="sw_id"
                                        :filter="(item) => item"
                                        :search-input.sync="writerSearch"
                                        hide-details
                                        label="Writer"
                                        :readonly="writerReadonly"
                                        :disabled="writerReadonly"
                                        :rules="[rules.required]"
                                    >
                                        <template
                                            slot="selection"
                                            slot-scope="{ item }"
                                        >
                                            <writer-avatar
                                                :id="item.sw_id"
                                                :feedbacks="item.fb_reviews"
                                                :rating="item.fb_rating"
                                                :rating_rc="item.rank_rc"
                                                :applied="item.created_at"
                                                :status="item.trust_status"
                                                :firstname="item.firstname"
                                                :lastname="item.lastname"
                                                :avatar-url="item.avatar_url"
                                                :avatar-fallback="item.avatar_fallback"
                                                :order_limit="item.projects_limit"
                                                :country="item.country"
                                                :country-nice-name="item.country_nice_name"
                                            />
                                            <div class="ml-4">
                                                <div class="d-flex">
                                                    <span
                                                        class="font-weight-bold mb-0 black--text text-decoration-none"
                                                    >
                                                        {{ item.firstname }}
                                                        {{ item.lastname }}
                                                    </span>
                                                    <span
                                                        v-if="item.notes_count > 0"
                                                        class="badge ml-3"
                                                        style="cursor: pointer;"
                                                        @click.stop="showNotes"
                                                    >
                                                        <b>{{ item.notes_count }}</b> {{ `${item.notes_count === 1 ? 'note' : 'notes'}` }}
                                                    </span>
                                                </div>

                                                <p class="grey--text mb-0">
                                                    {{ item.sw_id }}
                                                </p>
                                            </div>
                                        </template>
                                        <template
                                            slot="item"
                                            slot-scope="{ item }"
                                        >
                                            <writer-avatar
                                                :id="item.sw_id"
                                                :feedbacks="item.fb_reviews"
                                                :rating="item.fb_rating"
                                                :rating_rc="item.rank_rc"
                                                :applied="item.created_at"
                                                :status="item.trust_status"
                                                :firstname="item.firstname"
                                                :lastname="item.lastname"
                                                :avatar-url="item.avatar_url"
                                                :avatar-fallback="item.avatar_fallback"
                                                :order_limit="item.projects_limit"
                                                :country="item.country"
                                                :country-nice-name="item.country_nice_name"
                                            />
                                            <div class="ml-4">
                                                <div class="d-flex">
                                                    <span
                                                        class="font-weight-bold mb-0 black--text text-decoration-none"
                                                    >
                                                        {{ item.firstname }}
                                                        {{ item.lastname }}
                                                    </span>
                                                    <span
                                                        v-if="item.notes_count > 0"
                                                        class="badge ml-3"
                                                        style="cursor: pointer;"
                                                        @click.stop="showNotes"
                                                    >
                                                        <b>{{ item.notes_count }}</b> {{ `${item.notes_count === 1 ? 'note' : 'notes'}` }}
                                                    </span>
                                                </div>

                                                <p class="grey--text mb-0">
                                                    {{ item.sw_id }}
                                                </p>
                                            </div>
                                        </template>
                                        <template v-slot:append-item>
                                            <div v-intersect="endClient" />
                                        </template>
                                    </v-autocomplete>
                                </div>
                            </v-col>
                            <!-- order autocomplete -->
                            <v-col cols="12">
                                <div class="">
                                    <v-autocomplete
                                        ref="orderid"
                                        v-model="orderid"
                                        clearable
                                        color="accent"
                                        outlined
                                        :items="orders"
                                        :loading="orderLoading"
                                        item-value="orderid"
                                        :filter="(item) => item"
                                        :search-input.sync="orderSearch"
                                        hide-details
                                        label="Order ID"
                                        :readonly="!writer_id || orderReadonly"
                                        :disabled="!writer_id || orderReadonly"
                                    >
                                        <template
                                            slot="selection"
                                            slot-scope="{ item }"
                                        >
                                            <div class="d-flex flex-column">
                                                <div class="">
                                                    «{{ `${ item.project_information.title.length > 55 ? `${item.project_information.title.slice(0, 55)}...` : item.project_information.title}` }}»
                                                </div>
                                                <div class="">
                                                    {{ $date.dateFromNow(item.dates.created_at) }}, {{ item.orderid }}, {{ item.status }}
                                                </div>
                                            </div>
                                        </template>
                                        <template
                                            slot="item"
                                            slot-scope="{ item }"
                                        >
                                            <div class="d-flex flex-column w-full">
                                                <div class="">
                                                    «{{ `${ item.project_information.title.length > 55 ? `${item.project_information.title.slice(0, 55)}...` : item.project_information.title}` }}»
                                                </div>
                                                <div class="">
                                                    {{ $date.dateFromNow(item.dates.created_at) }}, {{ item.orderid }}, {{ item.status }}
                                                </div>
                                                <v-divider class="w-full mt-2" />
                                            </div>
                                        </template>
                                        <template v-slot:append-item>
                                            <div v-intersect="endOrderid" />
                                        </template>
                                    </v-autocomplete>
                                    <span
                                        v-if="!writer_id"
                                        class="d-block px-1 mt-1"
                                        :class="{'warning--text' : !writer_id}"
                                    >Select a writer before selecting an order</span>
                                </div>
                            </v-col>

                            <v-col cols="12">
                                <v-select
                                    v-model="msgtype"
                                    :items="msgtypes"
                                    color="accent"
                                    :rules="[rules.required]"
                                    outlined
                                    hide-details
                                    label="Type"
                                />
                            </v-col>
                            <v-col
                                v-if="msgtype === 'complaint'"
                                cols="12"
                            >
                                <v-select
                                    v-model="msgcategory"
                                    :items="msgproblems"
                                    color="accent"
                                    outlined
                                    :rules="[rules.requiredIf]"
                                    hide-details
                                    label="What is the problem?"
                                />
                            </v-col>
                            <v-col
                                v-if="msgtype === 'complaint'"
                                cols="12"
                            >
                                <v-select
                                    v-model="msgresolution"
                                    :items="msgresolutions"
                                    color="accent"
                                    :rules="[rules.requiredIf]"
                                    outlined
                                    hide-details
                                    label="How would you like it resolved?"
                                />
                            </v-col>
                            <v-col cols="12">
                                <v-text-field
                                    v-model="subject"
                                    color="accent"
                                    outlined
                                    hide-details
                                    :rules="[rules.required, rules.maxLengthSubject, rules.minLengthSubject]"
                                    label="Subject"
                                />
                            </v-col>
                            <v-col cols="12">
                                <v-textarea
                                    v-model="details"
                                    outlined
                                    color="accent"
                                    class="mt-3"
                                    label="Message"
                                    :rules="[rules.required, rules.minLengthMessage, rules.maxLengthMessage ]"
                                    counter
                                />
                            </v-col>
                        </v-row>
                    </v-form>
                </v-card-text>

                <slot
                    name="actions"
                >
                    <v-card-actions
                        class="py-5 px-5"
                    >
                        <v-btn
                            color="accent"
                            class="py-4 px-3 mr-4"
                            height="max-content"
                            :loading="loading"
                            :disabled="!isActiveConfirm"
                            @click="onConfirmModal"
                        >
                            confirm
                        </v-btn>

                        <v-btn
                            class="py-4 px-3"
                            height="max-content"
                            @click="onCloseModal"
                        >
                            cancel
                        </v-btn>
                    </v-card-actions>
                </slot>
            </v-card>
        </v-dialog>

        <ModalAlert
            v-if="showConfirmModal"
            title="Confirm the action"
            description="Do you really want to close the window? Your changes will not be saved"
            @confirmModal="onCloseModalMainModal"
            @closeModal="onCloseModalSubModal"
        />
    </div>
</template>

<script>
import { debounce } from 'lodash'
import { removeEmptyParamsFromRequest } from '@/helpers/utils/index.js'
import { eventBus } from '@/helpers/event-bus/'
import WriterAvatar from '@/components/shared/Avatars/WriterAvatar.vue'
import Api from '@/helpers/api/index.js'
import ModalAlert from '@/components/shared/Modals/ModalAlert'

export default {
    components: {
        WriterAvatar,
        ModalAlert
    },
    props: {
        current: {
            type: Object,
            required: false
        },
        title: {
            type: String,
            required: false,
            default: 'Create the support ticket'
        },
        maxWidth: {
            type: Number,
            default: 600
        },
        loading: {
            type: Boolean,
            required: false,
            default: false
        },
        isActiveConfirm: {
            type: Boolean,
            require: false,
            default: true
        }
    },
    data() {
        return {
            typesFromApi: {},
            showConfirmModal: false,
            showModal: true,
            domainReadonly: false,
            domain: '',
            status_priority: '',
            subject: '',
            details: '',
            msgtype: '',
            msgcategory: '',
            msgresolution: '',
            // order logic
            orderReadonly: false,
            orderid: '',
            orders: [],
            orderSearch: '',
            orderLoading: false,
            orderPage: 0,
            order_last_page: Infinity,
            orderSearchBy: 'orderid',
            // client logic
            writerReadonly: false,
            writers: [],
            writer_id: '',
            writerSearch: '',
            writerSearchBy: 'id',
            writerLoading: false,
            writerPage: 1,
            // helpers
            dataPrependedOrder: false,
            dataPrependedWriter: false,
            rules: {
                required: (value) => !!value || 'Required.',
                requiredIf: (value) => (this.msgtype === 'complaint' && !!value) || 'Required.',
                maxLengthMessage: (value) => value.length < 2048 || 'The details must not be greater than 2048 characters.',
                minLengthMessage: (value) => value.length >= 50 || 'The details must be at least 50 characters.',
                maxLengthSubject: (value) => value.length < 128 || 'The subject must not be greater than 128 characters.',
                minLengthSubject: (value) => value.length >= 2 || 'The subject must be at least 2 characters.'
            }
        }
    },
    computed: {
        priorities() {
            return this.typesFromApi.status_priority?.list
        },
        msgtypes() {
            return this.typesFromApi.msgtype?.list
        },
        msgresolutions() {
            return this.typesFromApi.msgresolution?.list
        },
        msgproblems() {
            return this.typesFromApi.msgcategory?.list
        }
    },
    watch: {
        writerSearch: {
            handler: debounce(async function () {
                this.writerPage = 1
                await this.makeWriterSearch()
            }, 300)
        },
        orderSearch: {
            handler: debounce(async function () {
                this.orderPage = 1
                if (this.writer_id) {
                    await this.makeOrderSearch()
                }
            }, 300)
        },
        async writer_id() {
            if (!this.dataPrependedOrder) {
                this.orderPage = 1
                this.orderid = ''
                await this.makeOrderSearch()
            }
        }
    },
    async created() {
        if (this.current) {
            const requests = []
            if (this.current.sw_id) {
                requests.push(this.prependWriter())
            }
            if (this.current.orderid) {
                requests.push(this.prependOrder())
            }
            Promise.all(requests)
        }
        await this.getTypesFromApi()
    },
    methods: {
        async prependOrder() {
            try {
                this.dataPrependedOrder = true
                this.orderid = this.current.orderid
                this.orderLoading = true
                const { data } = await Api.get('/api/order/list-by-params', removeEmptyParamsFromRequest({
                    mode: 'list',
                    page: 1,
                    per_page: 10,
                    search_by: 'orderid',
                    search_for: this.orderid
                }), {}, false)
                this.orders = [...data.data]
                this.orderReadonly = true
            } catch (error) {
                eventBus.$emit('showSnackBar', error, 'error');
            } finally {
                this.orderLoading = false
            }
        },
        async prependWriter() {
            try {
                this.dataPrependedWriter = true
                this.writer_id = this.current.sw_id
                this.writerLoading = true
                const { data } = await Api.get('/api/writer/list-by-params', removeEmptyParamsFromRequest({
                    mode: 'list',
                    page: 1,
                    per_page: 10,
                    search_by: 'sw_id',
                    search_for: this.writer_id
                }), {}, false)
                this.writers = [...data.data]
                this.writerReadonly = true
            } catch (error) {
                eventBus.$emit('showSnackBar', error, 'error');
            } finally {
                this.writerLoading = false
            }
        },
        onCloseModal() {
            this.showConfirmModal = true
        },
        onCloseModalMainModal() {
            this.showConfirmModal = false
            this.showModal = false
            this.$emit('closeModal', true)
        },
        onCloseModalSubModal() {
            this.showConfirmModal = false
        },
        onConfirmModal() {
            this.$refs.form.validate()
            this.$nextTick(() => {
                if (this.valid) {
                    this.$emit('confirmModal', {
                        writer_id: `${this.writer_id}`,
                        orderid: this.orderid,
                        status_priority: this.status_priority,
                        subject: this.subject,
                        details: this.details,
                        msgtype: this.msgtype,
                        msgcategory: this.msgcategory,
                        msgresolution: this.msgresolution
                    })
                } else {
                    eventBus.$emit('showSnackBar', 'Please complete form', 'error');
                }
            })
        },
        getIcon(str) {
            switch (str) {
            case 'NORMAL':
                return 'status_medium.svg'
            case 'HIGH':
                return 'status_high.svg'
            case 'LOW':
                return 'status_low.svg'
            default:
                return ''
            }
        },
        // User select
        async getClients(payload) {
            this.writerLoading = true
            try {
                const { data } = await Api.get('/api/writer/list-by-params', removeEmptyParamsFromRequest({
                    ...payload, page: this.writerPage, mode: 'list'
                }))
                const { data: totals } = await Api.get('/api/writer/list-by-params', removeEmptyParamsFromRequest({
                    ...payload, page: this.writerPage, mode: 'count'
                }))
                this.writers = [...this.writers, ...data.data]
                this.client_last_page = Math.ceil(totals.count / 10)
            } catch (error) {
                eventBus.$emit('showSnackBar', error, 'error');
            } finally {
                this.writerLoading = false
            }
        },
        async endClient(entries, observer, isIntersecting) {
            if (isIntersecting && this.client_last_page >= this.writerPage) {
                this.writerPage += 1
                await this.getClients({ search_by: this.writerSearchBy, search_for: this.writerSearch })
            }
        },
        async makeWriterSearch() {
            if (!this.dataPrependedWriter) {
                this.writers = []
                this.client_last_page = Infinity
                if (this.writerSearch && !Number.isNaN(+this.writerSearch)) {
                    this.writerSearchBy = 'sw_id'
                } else {
                    this.writerSearchBy = 'firstname'
                }
                await this.getClients({ search_by: this.writerSearchBy, search_for: this.writerSearch })
            }
        },
        // Order select
        async getOrders(payload) {
            this.orderLoading = true
            try {
                const { data } = await Api.get('/api/order/list-by-params', removeEmptyParamsFromRequest({
                    ...payload, page: this.orderPage, mode: 'list', writer_id: this.writer_id
                }))
                const { data: totals } = await Api.get('/api/order/list-by-params', removeEmptyParamsFromRequest({
                    ...payload,
                    page: this.orderPage,
                    mode: 'count',
                    writer_id: this.writer_id
                }))

                this.orders = [...this.orders, ...data.data]
                this.order_last_page = Math.ceil(totals.count / 10)
            } catch (error) {
                eventBus.$emit('showSnackBar', error, 'error');
            } finally {
                this.orderLoading = false
            }
        },
        async makeOrderSearch() {
            if (!this.dataPrependedOrder) {
                this.orders = []
                this.order_last_page = Infinity
                if (this.orderSearch && this.orderSearch.toLowerCase().includes('s-')) {
                    this.orderSearchBy = 'orderid'
                } else {
                    this.orderSearchBy = 'title'
                }
                await this.getOrders({ search_by: this.orderSearchBy, search_for: this.orderSearch })
            }
        },
        endOrderid(entries, observer, isIntersecting) {
            if (isIntersecting && this.order_last_page >= this.orderPage) {
                this.orderPage += 1
                this.getOrders({ search_by: this.orderSearchBy, search_for: this.orderSearch })
            }
        },
        async getTypesFromApi() {
            const { data } = await Api.get('/api/support/writer/get-types-for-html')
            this.typesFromApi = data
        }
    }
}
</script>

<style lang="scss">
    .search_by_client{
        max-width: 140px;
        fieldset{
            border-right: 0 !important;
            border-top-right-radius: 0 !important;
            border-bottom-right-radius: 0 !important;
        }
    }
</style>
