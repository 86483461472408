<template>
    <div class="domain">
        <v-tooltip
            top
            color="primary"
        >
            <template v-slot:activator="{ on, attrs }">
                <v-avatar
                    :color="showInitials ? 'primary' : ''"
                    size="30"
                    v-bind="attrs"
                    v-on="on"
                >
                    <img
                        v-if="!showInitials"
                        :src="require(`@images/logos/${getFavicon()}.svg`)"
                        :alt="getFavicon()"
                    >
                    <span
                        v-else
                        class="text--white font-weight-bold text-uppercase"
                    >
                        {{ getFavicon() }}
                    </span>
                </v-avatar>
            </template>
            <span>{{ tooltipTitle || domain }}</span>
        </v-tooltip>
    </div>
</template>

<script>
export default {
    props: {
        domain: {
            type: String
        },
        tooltipTitle: {
            type: String,
            required: false
        }
    },
    data() {
        return {
            showInitials: false,
            icons: [
                {
                    domain: 'customessaymeister.com',
                    initials: 'CE'
                },
                {
                    domain: 'genuinewriting.com',
                    initials: 'GW'
                },
                {
                    domain: 'professays.com',
                    initials: 'PE'
                },
                {
                    domain: 'dreamessays.com',
                    initials: 'DE'
                }
            ]
        }
    },
    methods: {
        getFavicon() {
            const [domain] = this.icons?.filter((item) => item.domain === this.domain)
            if (!domain?.initials) {
                this.showInitials = true
                return `${this.domain ? `${this.domain[0]}${this.domain[1]}` : ''}`
            }
            return domain.initials
        }
    }
}
</script>

<style lang="scss">
    .domain{
        display: flex;
        justify-content: center;
        &-image {
            min-width: 30px;
            width: 30px;
            max-width: 100%;
        }
    }
</style>
