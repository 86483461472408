<template>
    <v-chip
        :color="chipsColor(type).bg"
        :text-color="chipsColor(type).color"
        style="min-width: max-content;"
    >
        {{ type }}
    </v-chip>
</template>

<script>
export default {
    props: {
        type: {
            type: String,
            required: false,
            default: 'EMPTY'
        }
    },
    methods: {
        chipsColor(str) {
            str = str.toUpperCase();
            if (str === 'REFUND' || str === 'CLOSED') {
                return {
                    bg: 'rgba(255, 0, 0, 0.1)',
                    color: 'rgba(255, 0, 0, 1)'
                }
            }
            if (str === 'RETURN') {
                return {
                    bg: 'rgba(255, 0, 0, 0.2)',
                    color: 'rgba(255, 0, 0, 1)'
                }
            }
            if (str === 'INQUIRY') {
                return {
                    bg: 'rgba(25, 118, 210, 0.1)',
                    color: '#1976D2'
                }
            }
            if (str === 'COMPLETE' || str === 'OPEN' || str === 'RECEIVED') {
                return {
                    bg: 'rgba(39, 217, 31, 0.1)',
                    color: 'rgba(39, 217, 31, 1)'
                }
            }
            if (str === 'PAID') {
                return {
                    bg: 'rgba(39, 217, 31, 0.2)',
                    color: 'rgba(39, 217, 31, 1)'
                }
            }

            return {
                bg: 'rgba(240, 125, 0, 0.1)',
                color: '#284067'
            }
        }
    }
}
</script>

<style>

</style>
