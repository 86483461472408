<template>
    <v-tooltip
        top
        color="primary"
    >
        <template v-slot:activator="{ on, attrs }">
            <div
                class="user-avatar"
                v-bind="attrs"
                v-on="on"
            >
                <a
                    class="text-decoration-none black--text"
                    :href="`/hr/clients/${id}`"
                    target="_blank"
                >
                    <avatar-rolles
                        :access-status="'client'"
                        :firstname="firstname"
                        :lastname="lastname"
                        :avatar-url="avatar"
                        avatar-size="43"
                    />

                    <span
                        v-if="ordersCount || ordersCount === 0"
                        class="user-avatar_order-counter"
                    >
                        {{ ordersCount }}
                    </span>
                    <span
                        v-if="clientCountry"
                        class="user-avatar_flag"
                    >
                        <span :class="'flag-icon flag-input-icon flag-icon-' + clientCountry.toLowerCase()" />
                    </span>
                </a>
            </div>
        </template>
        <div>
            <p
                v-if="firstname && lastname"
                class="mb-0"
            >
                {{ nameCapitalized(firstname) }} {{ nameCapitalized(lastname) }}
            </p>
            <p
                v-else
                class="mb-0"
            >
                Anonymous
            </p>
            <p class="mb-0">
                {{ clientEmail }}
            </p>
            <p class="mb-0">
                {{ id }}
            </p>
            <p class="mb-0">
                {{ countryNiceName }}
            </p>
        </div>
    </v-tooltip>
</template>

<script>

import { nameCapitalized } from '@/helpers/capitalized/index'
import AvatarRolles from '@/components/shared/Avatars/AvatarRolles'

export default {
    components: {
        AvatarRolles
    },
    props: {
        firstname: {
            type: String,
            required: true
        },
        lastname: {
            type: String,
            required: true
        },
        id: {
            type: Number,
            required: true
        },
        ordersCount: {
            type: Number,
            required: false,
            default: 0
        },
        clientCountry: {
            type: String,
            required: false,
            default: ''
        },
        clientEmail: {
            type: String,
            required: false,
            default: ''
        },
        countryNiceName: {
            type: String,
            required: false,
            default: ''
        },
        avatar: {
            type: String,
            required: false,
            default: ''
        }
    },
    methods: {
        nameCapitalized
    }
}
</script>

<style lang="scss">
    .user-avatar{
        position: relative;
        height: max-content;
        width: max-content;
        &_order-counter{
            background: #1976d2;
            border-radius: 4px;
            padding: 3px;
            font-size: 12px;
            line-height: 1;
            color: #fff;
            font-weight: bold;
            position: absolute;
            left: 33px;
            white-space: nowrap;
        }
        &_flag {
            width: 18px;
            height: 14px;
            border-radius: 4px;
            position: absolute;
            bottom: 0;
            left: 33px;
            overflow: hidden;
            .flag-icon{
                width: 100%;
                height: 100%;
                margin-right: 0;
            }
        }
    }
</style>
