<template>
    <div class="">
        <v-dialog
            v-model="showModal"
            :max-width="maxWidth"
            persistent
            @click:outside="onCloseModal"
        >
            <v-card>
                <v-card-title class="text-subtitle-1 py-5 px-5 modal--border d-flex justify-space-between align-center">
                    <span>
                        <span>{{ title }}</span>
                    </span>
                    <v-hover
                        v-slot="{ hover }"
                        style="cursor: pointer;"
                    >
                        <v-icon
                            :color="hover ? '#000' : 'gray'"
                            @click="onCloseModal"
                        >
                            mdi-close
                        </v-icon>
                    </v-hover>
                </v-card-title>

                <v-card-text :class="`pt-5 pb-0 px-5`">
                    <v-form
                        ref="form"
                        v-model="valid"
                        lazy-validation
                    >
                        <v-row>
                            <v-col cols="12">
                                <v-select
                                    v-model="status_priority"
                                    :items="priorities"
                                    hide-details
                                    label="Priority"
                                    :rules="[rules.required]"
                                    outlined
                                >
                                    <template
                                        slot="selection"
                                        slot-scope="{ item }"
                                    >
                                        <img
                                            v-if="item.value !== 'all'"
                                            class="mr-2"
                                            :src="require(`@images/svg/${getIcon(item.value)}`)"
                                            :alt="item.value"
                                        >
                                        {{ item.text }}
                                    </template>
                                    <template
                                        slot="item"
                                        slot-scope="{ item }"
                                    >
                                        <img
                                            v-if="item.value !== 'all'"
                                            class="mr-2"
                                            :src="require(`@images/svg/${getIcon(item.value)}`)"
                                            :alt="item.value"
                                        >
                                        {{ item.text }}
                                    </template>
                                </v-select>
                            </v-col>
                            <!-- client autocomplete -->
                            <v-col cols="12">
                                <div class="d-flex">
                                    <v-autocomplete
                                        v-model="client_id"
                                        clearable
                                        color="accent"
                                        outlined
                                        :items="clients"
                                        :loading="clientLoading"
                                        item-value="id"
                                        :filter="(item) => item"
                                        :search-input.sync="clientSearch"
                                        hide-details
                                        label="Client"
                                        :readonly="clientReadonly"
                                        :disabled="clientReadonly"
                                        :rules="[rules.required]"
                                    >
                                        <template
                                            slot="selection"
                                            slot-scope="{ item }"
                                        >
                                            <v-avatar
                                                color="rgba(240, 125, 0, 0.1)"
                                                size="25"
                                                class="mr-2"
                                            >
                                                <span class="accent--text font-weight-bold ">
                                                    {{ `${item.firstname.charAt(0)}${item.lastname.charAt(0)}` }}
                                                </span>
                                            </v-avatar>
                                            <div class="d-flex flex-column">
                                                <span class="">{{ item.firstname }} {{ item.lastname }}</span>
                                                <span class="">ID: {{ item.id }}</span>
                                            </div>
                                        </template>
                                        <template
                                            slot="item"
                                            slot-scope="{ item }"
                                        >
                                            <v-avatar
                                                color="rgba(240, 125, 0, 0.1)"
                                                size="25"
                                                class="mr-2"
                                            >
                                                <span class="accent--text font-weight-bold text-caption">
                                                    {{ `${item.firstname.charAt(0)}${item.lastname.charAt(0)}` }}
                                                </span>
                                            </v-avatar>
                                            <div class="d-flex flex-column">
                                                <span class="text-caption">{{ item.firstname }} {{ item.lastname }}</span>
                                                <span class="text-caption">ID: {{ item.id }}</span>
                                            </div>
                                        </template>
                                        <template v-slot:append-item>
                                            <div v-intersect="endClient" />
                                        </template>
                                    </v-autocomplete>
                                </div>
                            </v-col>
                            <!-- order autocomplete -->
                            <v-col cols="12">
                                <div class="">
                                    <v-autocomplete
                                        ref="orderid"
                                        v-model="orderid"
                                        clearable
                                        color="accent"
                                        outlined
                                        :items="orders"
                                        :loading="orderLoading"
                                        item-value="orderid"
                                        :filter="(item) => item"
                                        :search-input.sync="orderSearch"
                                        hide-details
                                        label="Order ID"
                                        :readonly="!client_id || orderReadonly"
                                        :disabled="!client_id || orderReadonly"
                                    >
                                        <template
                                            slot="selection"
                                            slot-scope="{ item }"
                                        >
                                            <div class="d-flex flex-column">
                                                <div class="">
                                                    «{{ `${ item.project_information.title.length > 55 ? `${item.project_information.title.slice(0, 55)}...` : item.project_information.title}` }}»
                                                </div>
                                                <div class="">
                                                    {{ $date.dateFromNow(item.dates.created_at) }}, {{ item.orderid }}, {{ item.status }}
                                                </div>
                                            </div>
                                        </template>
                                        <template
                                            slot="item"
                                            slot-scope="{ item }"
                                        >
                                            <div class="d-flex flex-column w-full">
                                                <div class="">
                                                    «{{ `${ item.project_information.title.length > 55 ? `${item.project_information.title.slice(0, 55)}...` : item.project_information.title}` }}»
                                                </div>
                                                <div class="">
                                                    {{ $date.dateFromNow(item.dates.created_at) }}, {{ item.orderid }}, {{ item.status }}
                                                </div>
                                                <v-divider class="w-full mt-2" />
                                            </div>
                                        </template>
                                        <template v-slot:append-item>
                                            <div v-intersect="endOrderid" />
                                        </template>
                                    </v-autocomplete>
                                    <span
                                        v-if="!client_id"
                                        class="d-block px-1 mt-1"
                                        :class="{'warning--text' : !client_id}"
                                    >Select a client before selecting an order</span>
                                </div>
                            </v-col>

                            <v-col cols="12">
                                <v-select
                                    v-model="msgtype"
                                    :items="msgtypes"
                                    color="accent"
                                    outlined
                                    hide-details
                                    :rules="[rules.required]"
                                    label="Type"
                                />
                            </v-col>
                            <v-col
                                v-if="msgtype === 'complaint'"
                                cols="12"
                            >
                                <v-select
                                    v-model="msgcategory"
                                    :items="msgproblems"
                                    color="accent"
                                    outlined
                                    :rules="[rules.requiredIf]"
                                    hide-details
                                    label="What is the problem?"
                                />
                            </v-col>
                            <v-col
                                v-if="msgtype === 'complaint'"
                                cols="12"
                            >
                                <v-select
                                    v-model="msgresolution"
                                    :items="msgresolutions"
                                    color="accent"
                                    outlined
                                    :rules="[rules.requiredIf]"
                                    hide-details
                                    label="How would you like it resolved?"
                                />
                            </v-col>
                            <v-col cols="12">
                                <v-text-field
                                    v-model="subject"
                                    color="accent"
                                    :rules="[rules.required, rules.maxLengthSubject, rules.minLengthSubject]"
                                    outlined
                                    hide-details
                                    label="Subject"
                                />
                            </v-col>
                            <v-col cols="12">
                                <v-textarea
                                    v-model="details"
                                    :rules="[rules.required, rules.minLengthMessage, rules.maxLengthMessage ]"
                                    outlined
                                    color="accent"
                                    class="mt-3"
                                    label="Message"
                                    counter
                                />
                            </v-col>
                        </v-row>
                    </v-form>
                </v-card-text>

                <slot
                    name="actions"
                >
                    <v-card-actions
                        class="py-5 px-5"
                    >
                        <v-btn
                            color="accent"
                            class="py-4 px-3 mr-4"
                            height="max-content"
                            :loading="loading"
                            :disabled="!isActiveConfirm"
                            type="submit"
                            @click="onConfirmModal"
                        >
                            confirm
                        </v-btn>

                        <v-btn
                            class="py-4 px-3"
                            height="max-content"
                            @click="onCloseModal"
                        >
                            cancel
                        </v-btn>
                    </v-card-actions>
                </slot>
            </v-card>
        </v-dialog>

        <ModalAlert
            v-if="showConfirmModal"
            title="Confirm the action"
            description="Do you really want to close the window? Your changes will not be saved"
            @confirmModal="onCloseModalMainModal"
            @closeModal="onCloseModalSubModal"
        />
    </div>
</template>

<script>
import { debounce } from 'lodash'
import { removeEmptyParamsFromRequest } from '@/helpers/utils/index.js'
import { eventBus } from '@/helpers/event-bus/'

import ModalAlert from '@/components/shared/Modals/ModalAlert'

import Api from '@/helpers/api/index.js'

export default {
    components: {
        ModalAlert
    },
    props: {
        current: {
            type: Object,
            required: false
        },
        title: {
            type: String,
            required: false,
            default: 'Create the support ticket'
        },
        maxWidth: {
            type: Number,
            default: 600
        },
        loading: {
            type: Boolean,
            required: false,
            default: false
        },
        isActiveConfirm: {
            type: Boolean,
            require: false,
            default: true
        }
    },
    data() {
        return {
            typesFromApi: {},
            valid: false,
            showConfirmModal: false,
            showModal: true,
            domainReadonly: false,
            domain: '',
            status_priority: '',
            subject: '',
            details: '',
            msgtype: '',
            msgcategory: '',
            msgresolution: '',
            // order logic
            orderReadonly: false,
            orderid: '',
            orders: [],
            orderSearch: '',
            orderLoading: false,
            orderPage: 0,
            order_last_page: Infinity,
            orderSearchBy: 'orderid',
            // client logic
            clientReadonly: false,
            clients: [],
            client_id: '',
            clientSearch: '',
            clientSearchBy: 'id',
            clientLoading: false,
            clientPage: 1,
            // helpers
            dataPrependedOrder: false,
            dataPrependedClient: false,
            rules: {
                required: (value) => !!value || 'Required.',
                requiredIf: (value) => (this.msgtype === 'complaint' && !!value) || 'Required.',
                maxLengthMessage: (value) => value.length < 2048 || 'The details must not be greater than 2048 characters.',
                minLengthMessage: (value) => value.length >= 50 || 'The details must be at least 50 characters.',
                maxLengthSubject: (value) => value.length < 128 || 'The subject must not be greater than 128 characters.',
                minLengthSubject: (value) => value.length >= 2 || 'The subject must be at least 2 characters.'
            }
        }
    },
    computed: {
        priorities() {
            return this.typesFromApi.status_priority?.list
        },
        msgtypes() {
            return this.typesFromApi.msgtype?.list
        },
        msgresolutions() {
            return this.typesFromApi.msgresolution?.list
        },
        msgproblems() {
            return this.typesFromApi.msgcategory?.list
        }
    },
    watch: {
        clientSearch: {
            handler: debounce(async function () {
                this.clientPage = 1
                await this.makeClientSearch()
            }, 300)
        },
        orderSearch: {
            handler: debounce(async function () {
                this.orderPage = 1
                if (this.client_id) {
                    await this.makeOrderSearch()
                }
            }, 300)
        },
        async client_id() {
            if (!this.dataPrependedOrder) {
                this.orderPage = 1
                this.orderid = ''
                await this.makeOrderSearch()
            }
        }
    },
    async created() {
        if (this.current) {
            const requests = []
            if ((this.current.client && this.current.client.id) || this.current.client_id) {
                requests.push(this.prependClient())
            }
            if (this.current.orderid) {
                requests.push(this.prependOrder())
            }
            Promise.all(requests)
        }
        await this.getTypesFromApi()
    },
    methods: {
        onCloseModal() {
            this.showConfirmModal = true
        },
        onCloseModalMainModal() {
            this.showConfirmModal = false
            this.showModal = false
            this.$emit('closeModal', true)
        },
        onCloseModalSubModal() {
            this.showConfirmModal = false
        },
        onConfirmModal() {
            this.$refs.form.validate()
            this.$nextTick(() => {
                if (this.valid) {
                    this.$emit('confirmModal', {
                        clientid: `${this.client_id}`,
                        orderid: this.orderid || '',
                        status_priority: this.status_priority,
                        subject: this.subject,
                        details: this.details,
                        msgtype: this.msgtype,
                        msgcategory: this.msgcategory,
                        msgresolution: this.msgresolution,
                        domain: this.domain
                    })
                } else {
                    eventBus.$emit('showSnackBar', 'Please complete form', 'error');
                }
            })
        },
        getIcon(str) {
            switch (str) {
            case 'NORMAL':
                return 'status_medium.svg'
            case 'HIGH':
                return 'status_high.svg'
            case 'LOW':
                return 'status_low.svg'
            default:
                return ''
            }
        },
        async prependOrder() {
            try {
                this.dataPrependedOrder = true
                this.orderid = this.current.orderid
                this.orderLoading = true
                const { data } = await Api.get('/api/order/list-by-params', removeEmptyParamsFromRequest({
                    mode: 'list',
                    page: 1,
                    per_page: 10,
                    search_by: 'orderid',
                    search_for: this.current.orderid
                }), {}, false)
                this.orders = [...data.data]
                this.orderReadonly = true
            } catch (error) {
                eventBus.$emit('showSnackBar', error, 'error');
            } finally {
                this.orderLoading = false
            }
        },
        async prependClient() {
            try {
                this.dataPrependedClient = true
                this.client_id = this.current.client?.id || this.current.client_id
                this.clientLoading = true
                const { data } = await Api.get('/api/client/list-by-params', removeEmptyParamsFromRequest({
                    mode: 'list',
                    page: 1,
                    per_page: 10,
                    search_by: 'id',
                    search_for: this.client_id
                }), {}, false)
                this.clients = [...data.data]
                this.clientReadonly = true
            } catch (error) {
                eventBus.$emit('showSnackBar', error, 'error');
            } finally {
                this.clientLoading = false
            }
        },
        // User select
        async getClients(payload) {
            this.clientLoading = true
            try {
                const { data } = await Api.get('/api/client/list-by-params', removeEmptyParamsFromRequest({
                    ...payload, page: this.clientPage, mode: 'list'
                }), {}, false)
                const { data: totals } = await Api.get('/api/client/list-by-params', removeEmptyParamsFromRequest({
                    ...payload, page: this.clientPage, mode: 'count'
                }), {}, false)
                this.clients = [...this.clients, ...data.data]
                this.client_last_page = Math.ceil(totals.count / 10)
            } catch (error) {
                eventBus.$emit('showSnackBar', error, 'error');
            } finally {
                this.clientLoading = false
            }
        },
        async endClient(entries, observer, isIntersecting) {
            if (isIntersecting && this.client_last_page >= this.clientPage) {
                this.clientPage += 1
                await this.getClients({ search_by: this.clientSearchBy, search_for: this.clientSearch })
            }
        },
        async makeClientSearch() {
            if (!this.dataPrependedClient) {
                this.clients = []
                this.client_last_page = Infinity
                if (this.clientSearch && !Number.isNaN(+this.clientSearch)) {
                    this.clientSearchBy = 'id'
                } else {
                    this.clientSearchBy = 'firstname'
                }
                await this.getClients({ search_by: this.clientSearchBy, search_for: this.clientSearch })
            }
        },
        // Order select
        async getOrders(payload) {
            this.orderLoading = true
            try {
                const { data } = await Api.get('/api/order/list-by-params', removeEmptyParamsFromRequest({
                    ...payload, page: this.orderPage, mode: 'list', client_id: this.client_id
                }, {}, false))
                const { data: totals } = await Api.get('/api/order/list-by-params', removeEmptyParamsFromRequest({
                    ...payload,
                    page: this.orderPage,
                    mode: 'count',
                    client_id: this.client_id
                }, {}, false))

                this.orders = [...this.orders, ...data.data]
                this.order_last_page = Math.ceil(totals.count / 10)
            } catch (error) {
                eventBus.$emit('showSnackBar', error, 'error');
            } finally {
                this.orderLoading = false
            }
        },
        async makeOrderSearch() {
            if (!this.dataPrependedOrder) {
                this.orders = []
                this.order_last_page = Infinity
                if (this.orderSearch && this.orderSearch.toLowerCase().includes('s-')) {
                    this.orderSearchBy = 'orderid'
                } else {
                    this.orderSearchBy = 'title'
                }
                await this.getOrders({ search_by: this.orderSearchBy, search_for: this.orderSearch })
            }
        },
        endOrderid(entries, observer, isIntersecting) {
            if (isIntersecting && this.order_last_page >= this.orderPage) {
                this.orderPage += 1
                this.getOrders({ search_by: this.orderSearchBy, search_for: this.orderSearch })
            }
        },
        async getTypesFromApi() {
            const { data } = await Api.get('/api/support/client/get-types-for-html')
            this.typesFromApi = data
        }
    }
}
</script>

<style lang="scss">
    .search_by_client{
        max-width: 140px;
        fieldset{
            border-right: 0 !important;
            border-top-right-radius: 0 !important;
            border-bottom-right-radius: 0 !important;
        }
    }
</style>
