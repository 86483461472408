<template>
    <v-col
        cols="12"
        :class="`${isRightSideMessage ? 'ml-auto' : 'mr-auto'}`"
        md="6"
    >
        <p
            class="gray--text mb-0 w-full d-flex top-line"
            :class="isRightSideMessage ? 'top-line--right' : 'top-line--left'"
        >
            <span class="text-subtitle-2">
                {{ message.author.name ? message.author.name : message.author.firstname }}
                <span
                    class="grey--text text-none font-weight-light ml-2"
                >
                    <router-link
                        v-if="message.author.type === 'Writer'"
                        target="_blank"
                        :to="{
                            name: setRoute,
                            params: {
                                id: message.author.id
                            }
                        }"
                    >
                        {{ message.author.type }}
                    </router-link>
                    <router-link
                        v-else-if="message.author.type === 'Client'"
                        target="_blank"
                        :to="{
                            name: 'hr-clients-details',
                            params: {
                                id: message.author.id
                            }
                        }"
                    >
                        {{ message.author.type }}
                    </router-link>
                    <span v-else>{{ message.author.type }}</span>
                    ID: {{ message.author.id }}
                </span>
            </span>
        </p>
        <div
            class="d-flex message"
            :class="{'message--right': isRightSideMessage}"
        >
            <div
                class="d-flex mr-2"
                :class="{
                    'order-1': isRightSideMessage,
                    'mr-2': !isRightSideMessage,
                    'ml-2': isRightSideMessage,
                }"
            >
                <avatar-rolles
                    :access-status="message.author.type"
                    :firstname="message.author.firstname"
                    :lastname="message.author.lastname"
                    :avatar-url="message.author.avatar_url"
                    :avatar-fallback="message.author.avatar_fallback"
                    :is-accent="isByClient"
                    avatar-size="50"
                />
            </div>
            <div
                class="message__data py-5 px-5 black--text"
                :class="{'message__data--admin': isRightSideMessage}"
            >
                <div class="d-flex">
                    <div v-html="splitByCharacters" />
                </div>
            </div>
        </div>
        <div
            :class="isRightSideMessage ? 'top-line--right' : 'top-line--left'"
        >
            <span class="mr-4 grey--text">
                {{ $date.dateFromNow(message.created_at) }}
            </span>
            <span class="grey--text">
                {{ $date.humanizeDate(message.created_at) }}
            </span>
        </div>
    </v-col>
</template>

<script>
import { mapGetters } from 'vuex';

import AvatarRolles from '@/components/shared/Avatars/AvatarRolles'

export default {
    components: {
        AvatarRolles
    },
    props: {
        message: {
            type: Object,
            required: true
        },
        ownerRoles: {
            type: Array,
            required: true
        }
    },
    computed: {
        ...mapGetters('client', [
            'getterAdminId'
        ]),
        isRightSideMessage() {
            if (this.ownerRoles.includes('by-id')) {
                return this.message.author.id === this.getterAdminId
            }
            return this.ownerRoles.includes(this.message.author?.type)
        },
        splitByCharacters() {
            const splitString = this.message.details.split('\n').join('<br>')
            return splitString
        },
        setRoute() {
            if (this.$route.name === 'applicant') {
                return 'applicant-profile'
            }
            return 'writer-profile'
        },
        isByClient() {
            if (this.message.author.type !== 'Admin') {
                return true
            }
            return false
        }
    }
}
</script>

<style>

</style>
