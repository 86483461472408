<template>
    <v-container
        ref="chat_items"
        fluid
        class="chat_items pt-0 d-flex flex-column"
    >
        <div
            ref="messages"
            class="chat_items--messages pt-3 flex-grow-1 d-flex flex-column"
        >
            <v-row
                v-for="item in chat"
                :key="item.id"
                class="ma-0"
            >
                <ChatMessage
                    :message="item"
                    :owner-roles="ownerRoles"
                />
            </v-row>
        </div>
        <v-divider class="my-6 mt-0" />
        <div
            class="chat_modal_typing-message"
        >
            <span>{{ typingMessage }}</span>
            <div
                v-if="typingMessage"
                class="dot-flashing"
            />
        </div>
        <v-form
            ref="form"
            v-model="valid"
            lazy-validation
            @submit.prevent="sendMessage"
        >
            <v-row class="bottom_edge">
                <v-col
                    cols="9"
                    md="10"
                >
                    <v-textarea
                        v-model="details"
                        label="Enter message"
                        placeholder="Enter message"
                        outlined
                        rows="2"
                        auto-grow
                        :rules="[rules.required, rules.minLenght, rules.maxLenght]"
                        clearable
                        @focus="onTyping"
                        @blur="onUnTyping"
                        @keyup.native="keyListner($event)"
                    />
                </v-col>
                <v-col
                    cols="3"
                    md="2"
                >
                    <v-btn
                        color="primary"
                        height="56px"
                        width="100%"
                        class="mr-5 mb-8"
                        :loading="loading"
                        :disabled="!valid"
                        type="submit"
                    >
                        send
                    </v-btn>
                </v-col>
            </v-row>
        </v-form>
    </v-container>
</template>

<script>
import ChatMessage from '@/components/Chat/ChatMessage'
// import { debounce } from 'lodash'

export default {
    name: 'Chat',
    components: {
        ChatMessage
    },
    props: {
        chat: {
            type: Array,
            required: true
        },
        loading: {
            type: Boolean
        },
        ownerRoles: {
            type: Array,
            required: false,
            default: () => ['Admin', 'Writer']
        },
        typingMessage: {
            type: String
        }
    },
    data() {
        return {
            details: '',
            valid: true,
            rules: {
                required: (value) => !!value || 'Required.',
                minLenght: (value) => value.length > 2 || 'Min 2 characters',
                maxLenght: (value) => value.length <= 2048 || 'Min 2048 characters'
            }
        }
    },
    // watch: {
    //     details() {
    //         this.$emit('typing', true)
    //         this.userTyping(this)
    //     }
    // },
    mounted() {
        this.$nextTick(() => {
            this.scrollToBottom()
        })
    },
    updated() {
        this.$nextTick(() => {
            this.scrollToBottom()
        })
    },
    methods: {
        sendMessage() {
            this.$emit('sendChatMessage', { details: this.details })
            this.details = ''
            this.resetValidation()
        },
        // userTyping: debounce((self) => {
        //     self.$emit('typing', false)
        // }, 1000),
        onTyping() {
            this.$emit('typing', true)
        },
        onUnTyping() {
            this.$emit('typing', false)
        },
        resetValidation() {
            this.$refs.form.resetValidation()
        },
        scrollToBottom() {
            const { chat_items, messages } = this.$refs
            chat_items.scrollTop = 9999999999
            messages.scrollTop = 9999999999
        },
        keyListner(e) {
            if (e.ctrlKey && e.keyCode === 13) {
                this.details += '\n'
                return
            }
            if (!e.ctrlKey && !e.shiftKey && e.keyCode === 13) {
                this.sendMessage()
                e.preventDefault();
            }
        }
    }
}
</script>

<style lang="scss">
    .message{
        &__data{
            width: calc(100% - 75px);
            background: #f2f6f9 ;
            border-radius: 12px;
            &--admin{
                background: #ddf1ff;
            }
            &--accent{
                background: #d7ebf7;
            }
        }
    }
    .top-line--right{
        padding-right: 95px !important;
        padding-left: 20px;
    }
    .top-line--left{
        padding-left: 75px !important;
        padding-right: 40px;
    }
    .chat_items{
        height: 100%;
        &--messages{
            max-height: 100%;
            overflow-y: auto;
            overflow-x: hidden;
            & >.row{
                flex: initial;
                &:first-child{
                    margin-top: auto;
                }
            }
        }
    }

    .dot-flashing {
        margin-left: 10px;
        position: relative;
        width: 6px;
        height: 6px;
        border-radius: 3px;
        background-color: #002f34;
        color: #002f34;
        animation: dot-flashing 1s infinite linear alternate;
        animation-delay: 0.5s;
    }
    .dot-flashing::before, .dot-flashing::after {
        content: "";
        display: inline-block;
        position: absolute;
        top: 0;
    }
    .dot-flashing::before {
        left: -8px;
        width: 6px;
        height: 6px;
        border-radius: 3px;
        background-color: #002f34;
        color: #002f34;
        animation: dot-flashing 1s infinite alternate;
        animation-delay: 0s;
    }
    .dot-flashing::after {
        left: 8px;
        width: 6px;
        height: 6px;
        border-radius: 3px;
        background-color: #002f34;
        color: #002f34;
        animation: dot-flashing 1s infinite alternate;
        animation-delay: 1s;
    }

    @keyframes dot-flashing {
        0% {
            background-color: #002f34;
        }
        50%, 100% {
            background-color: rgba(152, 128, 255, 0.2);
        }
    }
</style>
