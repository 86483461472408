export const TOOLBARS = [
    {
        name: 'bookmark',
        text: 'Bookmark',
        modal: {
            name: 'ModalTextarea',
            title: 'Bookmark',
            description: 'Bookmark an item. Any important order or ticket can be bookmarked for easy access in the future.',
            inputLabel: 'Add a description',
            success_message: 'The ticket is successfully added to bookmarks.'
        },
        icon: 'mdi-star-outline'
    },
    // {
    //     name: 'edit',
    //     text: 'Edit',
    //     modal: {
    //         name: 'ModalSupportEdit',
    //         success_message: 'The ticket status is updated'
    //     },
    //     icon: 'mdi-pencil'
    // },
    {
        name: 'order',
        text: 'Attach order',
        modal: {
            name: 'ModalLinkToOrder',
            success_message: 'Order was attached to ticket'
        },
        icon: 'mdi-barcode '
    },
    {
        name: 'transfer',
        text: 'Transfer',
        modal: {
            name: 'ModalTransfer',
            success_message: 'The ticket is successfully transfered.'
        },
        icon: 'mdi-subdirectory-arrow-right'
    },
    {
        name: 'chat',
        text: 'Chat',
        modal: {
            name: 'ModalChat'
        },
        icon: 'mdi-comment-plus-outline'
    },
    {
        name: 'resolve_ticket',
        text: 'Close Ticket',
        modal: {
            name: 'ModalAlert',
            title: 'Close Ticket',
            description: 'Close Ticket',
            success_message: 'The ticket was successfully closed'
        },
        icon: 'mdi-close'
    }
]

export const MASS_TOOLBARS = [
    {
        name: 'bookmark_mass',
        text: 'Bookmark',
        modal: {
            name: 'ModalTextarea',
            title: 'Bookmark',
            description: 'Bookmark an item. Any important order or ticket can be bookmarked for easy access in the future.',
            inputLabel: 'Add a description',
            success_message: 'The ticket(s) is successfully added to bookmarks.'
        },
        icon: 'mdi-star-outline'
    },
    {
        name: 'close_tickets',
        text: 'Close Tickets',
        modal: {
            name: 'ModalAlert',
            title: 'Close Tickets',
            description: 'Close Ticket',
            success_message: 'The ticket(s) was successfully closed'
        },
        icon: 'mdi-close'
    }
]

export const CARD_ACTIONS = [
    {
        name: 'bookmark',
        text: 'Bookmark',
        modal: {
            name: 'ModalTextarea',
            title: 'Bookmark',
            description: 'Bookmark an item. Any important order or ticket can be bookmarked for easy access in the future.',
            inputLabel: 'Add a description',
            success_message: 'The ticket is successfully added to bookmarks.'
        },
        icon: 'mdi-star-outline'
    },
    {
        name: 'transfer',
        text: 'Transfer',
        modal: {
            name: 'ModalTransfer',
            success_message: 'The ticket is successfully transfered.'
        },
        icon: 'mdi-subdirectory-arrow-right'
    },
    {
        name: 'close_ticket',
        text: 'Close Ticket',
        modal: {
            name: 'ModalAlert',
            title: 'Close Ticket',
            description: 'Close Ticket',
            success_message: 'The ticket was successfully closed'
        },
        icon: 'mdi-close'
    }
]
