var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-col',{class:("" + (_vm.isRightSideMessage ? 'ml-auto' : 'mr-auto')),attrs:{"cols":"12","md":"6"}},[_c('p',{staticClass:"gray--text mb-0 w-full d-flex top-line",class:_vm.isRightSideMessage ? 'top-line--right' : 'top-line--left'},[_c('span',{staticClass:"text-subtitle-2"},[_vm._v(" "+_vm._s(_vm.message.author.name ? _vm.message.author.name : _vm.message.author.firstname)+" "),_c('span',{staticClass:"grey--text text-none font-weight-light ml-2"},[(_vm.message.author.type === 'Writer')?_c('router-link',{attrs:{"target":"_blank","to":{
                        name: _vm.setRoute,
                        params: {
                            id: _vm.message.author.id
                        }
                    }}},[_vm._v(" "+_vm._s(_vm.message.author.type)+" ")]):(_vm.message.author.type === 'Client')?_c('router-link',{attrs:{"target":"_blank","to":{
                        name: 'hr-clients-details',
                        params: {
                            id: _vm.message.author.id
                        }
                    }}},[_vm._v(" "+_vm._s(_vm.message.author.type)+" ")]):_c('span',[_vm._v(_vm._s(_vm.message.author.type))]),_vm._v(" ID: "+_vm._s(_vm.message.author.id)+" ")],1)])]),_c('div',{staticClass:"d-flex message",class:{'message--right': _vm.isRightSideMessage}},[_c('div',{staticClass:"d-flex mr-2",class:{
                'order-1': _vm.isRightSideMessage,
                'mr-2': !_vm.isRightSideMessage,
                'ml-2': _vm.isRightSideMessage,
            }},[_c('avatar-rolles',{attrs:{"access-status":_vm.message.author.type,"firstname":_vm.message.author.firstname,"lastname":_vm.message.author.lastname,"avatar-url":_vm.message.author.avatar_url,"avatar-fallback":_vm.message.author.avatar_fallback,"is-accent":_vm.isByClient,"avatar-size":"50"}})],1),_c('div',{staticClass:"message__data py-5 px-5 black--text",class:{'message__data--admin': _vm.isRightSideMessage}},[_c('div',{staticClass:"d-flex"},[_c('div',{domProps:{"innerHTML":_vm._s(_vm.splitByCharacters)}})])])]),_c('div',{class:_vm.isRightSideMessage ? 'top-line--right' : 'top-line--left'},[_c('span',{staticClass:"mr-4 grey--text"},[_vm._v(" "+_vm._s(_vm.$date.dateFromNow(_vm.message.created_at))+" ")]),_c('span',{staticClass:"grey--text"},[_vm._v(" "+_vm._s(_vm.$date.humanizeDate(_vm.message.created_at))+" ")])])])}
var staticRenderFns = []

export { render, staticRenderFns }