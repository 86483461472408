<template>
    <modal
        :show-modal="showModal"
        @closeModal="onCloseModal"
        @confirmModal="onConfirmModal"
    >
        <template #title>
            <span>Transfer</span>
        </template>
        <template #body>
            <p>
                This tool will create an inbox notification for the selected users.
            </p>
            <v-row dense>
                <v-col cols="3">
                    <v-select
                        v-model="search_by"
                        :items="search_by_list"
                        label="Search by"
                        outlined
                        hide-details
                    />
                </v-col>
                <v-col cols="9">
                    <v-autocomplete
                        v-model="model"
                        :items="getterAdminsList"
                        :search-input.sync="search"
                        color="accent"
                        :item-text="search_by"
                        :item-value="search_by"
                        label="Admin"
                        placeholder="Start typing to Search"
                        outlined
                        hide-details
                        return-object
                    >
                        <template
                            slot="selection"
                            slot-scope="{ item }"
                        >
                            <avatar-rolles
                                :access-status="role(item)"
                                :firstname="item.firstname"
                                :lastname="item.lastname"
                                :avatar-url="item.avatar_url"
                                :avatar-fallback="item.avatar_fallback"
                                avatar-size="40"
                            />
                            <div class="d-flex flex-column ml-2">
                                <span class="">{{ item.firstname }} {{ item.lastname }}</span>
                                <span class="">ID: {{ item.id }}</span>
                            </div>
                        </template>
                        <template
                            slot="item"
                            slot-scope="{ item }"
                        >
                            <avatar-rolles
                                :access-status="role(item)"
                                :firstname="item.firstname"
                                :lastname="item.lastname"
                                :avatar-url="item.avatar_url"
                                :avatar-fallback="item.avatar_fallback"
                                avatar-size="45"
                            />
                            <div class="d-flex flex-column ml-2">
                                <span class="text-caption">{{ item.firstname }} {{ item.lastname }}</span>
                                <span class="text-caption">ID: {{ item.id }}</span>
                            </div>
                        </template>
                        <template v-slot:append-item>
                            <div v-intersect="endAdmin" />
                        </template>
                    </v-autocomplete>
                </v-col>
            </v-row>
            <v-row
                dense
                class="mt-2"
            >
                <v-col cols="12">
                    <v-textarea
                        v-model="transfer_details"
                        hide-details
                        placeholder="Enter your message"
                        outlined
                    />
                </v-col>
            </v-row>
        </template>
    </modal>
</template>

<script>
import { debounce } from 'lodash'
import { createNamespacedHelpers, mapGetters } from 'vuex';
import Modal from '@/components/shared/Modals/Modal'

import {
    ACTION_GET_ADMIN_LIST
} from '@/store/modules/admin/action-types';
import AvatarRolles from '@/components/shared/Avatars/AvatarRolles.vue';

const {
    mapActions: mapAdminActions
} = createNamespacedHelpers('admins');

export default {
    components: {
        Modal,
        AvatarRolles
    },
    data() {
        return {
            showModal: true,
            isLoading: true,
            admins: [],
            model: null,
            transfer_details: null,
            search: null,
            lastPage: null,
            adminPage: 1,
            search_by: 'firstname'
        }
    },
    computed: {
        ...mapGetters('admins', [
            'getterAdminsList',
            'getterLastPage',
            'getterCurrentPage'
        ]),
        search_by_list() {
            return [
                {
                    text: 'Firstname',
                    value: 'firstname'
                },
                {
                    text: 'Lastname',
                    value: 'lastname'
                },
                {
                    text: 'Email',
                    value: 'email'
                }
            ]
        }
    },
    watch: {
        search: debounce(async function (val) {
            this.adminPage = 1
            this[ACTION_GET_ADMIN_LIST]({
                search_by: this.search_by, search_for: val, page: this.adminPage
            })
        }, 500)
    },
    async created() {
        await this.getAdmins()
        // this[ACTION_GET_ADMIN_LIST]()
    },
    methods: {
        ...mapAdminActions({
            ACTION_GET_ADMIN_LIST
        }),
        onCloseModal() {
            this.showModal = false
            this.$emit('closeModal', true)
        },
        onConfirmModal() {
            this.$emit('confirmModal', {
                admin_id: this.model.id,
                transfer_details: this.transfer_details
            })
        },
        async getAdmins(payload) {
            this[ACTION_GET_ADMIN_LIST]({ ...payload, page: this.adminPage })
            // this.lastPage = data.last_page
            // this.writers = [...this.writers, ...data.data]
        },
        async endAdmin(entries, observer, isIntersecting) {
            if (isIntersecting && this.getterLastPage > this.adminPage) {
                this.adminPage += 1
                await this.getAdmins({ search_by: this.search_by, search_for: this.writerSearch })
            }
        },
        role(item) {
            if (item.roles.some((item) => item.name === 'super-admin')) {
                return 'superadmin'
            }
            return 'admin'
        }
    }
}
</script>

<style>

</style>
